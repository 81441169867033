import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../../shared/transaction.service';
import { PagerService } from '../../shared/pager.service';
import { Transaction } from '../../shared/transaction.model';

declare var $:any;
@Component({
  selector: 'app-transaction-donor',
  templateUrl: './transaction-donor.component.html',
  styleUrls: ['./transaction-donor.component.css']
})
export class TransactionDonorComponent implements OnInit {
  private allItems: any[];
  pager: any = {};  
  pagedItems: any[];
  errorRequest:any;
  listTransactionEmpty:any;
  currentTransaction: Transaction;

  validEmail;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  email: String = '';

  isEmailSended;
  isEmailNotSend;
  isEmailSending: Boolean = false;

  constructor(private transactionService: TransactionService, private pagerService: PagerService) { }

  ngOnInit() {
    let id = 'jaouad.essika@gmail.com'
    this.transactionService.getTransactionByDonator(id).subscribe(
      res => {
        console.log(res);
        console.log(res['transactions'].length);
        if(res['transactions'].length === 0) {
          this.listTransactionEmpty = true;
        } else {          
          this.listTransactionEmpty = false;
          this.allItems = res['transactions'];
          this.setPage(1);

        }
        
      },
      err => {
        this.errorRequest = true;
      });
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page,10);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  selectTransaction(transaction) {
    this.isEmailSending = false;
    this.isEmailNotSend = null;
    this.isEmailSended = null;
    this.validEmail = null;
    this.email = '';
    if (transaction.email !== 'null') {
      this.email = transaction.email;
    }
    this.currentTransaction = transaction;

  }

  selectCurrentCampaign(transaction) {
    this.currentTransaction = transaction;
  }
  sendEmailReceipt() {

    if (this.emailRegex.test(String(this.email).toLowerCase()) === true) {
      this.validEmail = null;
      this.isEmailSending = true;
      // SEND EMAIL
      this.currentTransaction['email'] = this.email;
      this.transactionService.sendReceiptTransaction(this.currentTransaction).subscribe(
        res => {
          this.isEmailSending = null;
          this.isEmailSended = true;
        },
        err => {
          this.isEmailSending = null;
          this.isEmailNotSend = true;
        }
      );
    } else {
      this.validEmail = false;
      setTimeout(() => {
        this.validEmail = null;
      }, 3000);
    }

  }
}


