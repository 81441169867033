import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../../shared/campaign.service';
import { StripeCheckout } from '../../shared/stripeCheckout.model';
import { environment } from '../../../environments/environment';
import { WebParam } from '../../shared/webParam.model';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';


declare var Stripe; // : stripe.StripeStatic;

@Component({
  selector: 'app-amount-campaign',
  templateUrl: './amount-campaign.component.html',
  styleUrls: ['./amount-campaign.component.css']
})
export class AmountCampaignComponent implements OnInit {

  webParam: WebParam;
  actif: boolean;

  nameColor: any;
  color: any;
  colorActive: any;

  counterCheckout: any = 3;
  isForm: boolean;
  isLoading: boolean;
  error: boolean = false;
  errorCheckout: boolean;
  stripeCheckout: StripeCheckout;


  constructor(private campaignService: CampaignService, private route: ActivatedRoute, private router: Router) {
    this.webParam = new WebParam();
    this.stripeCheckout = new StripeCheckout();
  }


  public loadExternalScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }


  ngAfterViewInit() {
    // this.loadExternalScript('assets/js/jquery.js');
    // this.loadExternalScript('assets/js/jquery.calendario.js');
    // this.loadExternalScript('assets/demos/nonprofit/js/events.js');
    this.loadExternalScript('assets/js/functions.js');
  }

  ngOnInit() {



this.campaignService.findCampaignByOrganization(this.route.snapshot.paramMap.get('organizationId'),this.route.snapshot.paramMap.get('campaignId')).subscribe(
  res => {
     
    console.log(res);
    
    // tslint:disable-next-line: no-string-literal
      this.webParam = res['organization'];
      console.log("this.webParam.kiosk id");
      console.log(this.webParam.kiosk);
      // this.actif = true;
      // this.webParam.kiosk.actif;
      this.stripeCheckout.idKiosk = this.webParam.kiosk.id;
      
      this.actif = this.webParam.kiosk.actif;
      this.nameColor = this.webParam.kiosk.template.nameColor;
      this.color = this.webParam.kiosk.template.color;
      this.color = this.color.substring(0, this.color.length - 1);
      this.colorActive = this.webParam.kiosk.template.colorActive;
      this.colorActive = this.colorActive.substring(0, this.colorActive.length - 1);

      this.stripeCheckout.campaign = this.webParam.campaign.idCampaign;
      this.stripeCheckout.title = this.webParam.campaign.title;
      this.stripeCheckout.description = this.webParam.campaign.description;
      this.stripeCheckout.icon = this.webParam.campaign.icon;
      // this.stripeCheckout.logo = 'https://www.e6kdonation.com/wp-content/uploads/2019/03/' + this.route.snapshot.paramMap.get('logo');
      // this.stripeCheckout.logo = this.route.snapshot.paramMap.get('logo');
      this.stripeCheckout.organization = this.webParam.idOrganization;
      this.stripeCheckout.stripe = this.webParam.id_connected_account;

      this.stripeCheckout.cancel_url = environment.apiBaseUrlStripe + '/don/' + res['organization'].linkDonation;
      // this.stripeCheckout.success_url = environment.apiBaseUrlStripe + '/don/' + res['organization'].linkDonation +'/sucess/' + res['organization'].linkDonation;
      this.stripeCheckout.success_url = environment.apiBaseUrlStripe + '/sucess/' + res['organization'].linkDonation;
      this.error = false;
  },
  err => {
    // this.webParam.email = 'assistance@e6kdonation.com';
    this.error = true;
    this.router.navigate(['don/organization/notfound']);
  });

this.isForm = true;
this.isLoading = false;
this.error = false;
  }


  numberOnly(event): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  createStripeSessionCheckout() {   
    ///let amount = Number(this.stripeCheckout.amount);
    this.counterCheckout --;
    if (this.counterCheckout === 0) {
      this.router.navigate(['don/' + this.webParam.linkDonation ]);
    }
    this.error = false;
    this.isLoading = true;
    this.isForm = false;

console.log("this.stripeCheckout");    
console.log(this.stripeCheckout);
    this.campaignService.createSessionStripeCheckout(this.stripeCheckout).subscribe(
      res => {
        const sessionId = res['session'].id;
        const stripe = Stripe('pk_test_KmiSL35NVxX8h8qrWQWm5L0h', {
          stripeAccount: this.stripeCheckout.stripe
        });
        stripe.redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: sessionId
        }).then(function (result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          // this.error = true;
          this.errorCheckout = true;
          this.isLoading = false;
        });
        // document.location.href = 'https://e6kdonation.com/checkout/stripe.html?stripe=pk_test_KmiSL35NVxX8h8qrWQWm5L0h&stripeaccount=acct_1GBIHWBRuv9WoXh1&id='+sessionId;
      },
      err => {
        this.errorCheckout = true;
        this.isLoading = false;
      });
  }

  navToDonorInformation() {
    this.stripeCheckout.amount = this.stripeCheckout.amount;
    this.router.navigate(['don/' + this.webParam.linkDonation + '/payment/donor/information/' + this.webParam.linkDonation + '/' + this.stripeCheckout.organization + '/' + this.stripeCheckout.campaign]);
  }

}



